<template>
  <div class="container--fluid pa-0">
    <the-header />
    <the-ticket-confirmation />
    <the-footer />
  </div>
</template>
<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";
import TheTicketConfirmation from "../components/TheTicketConfirmation.vue";

export default {
  name: "Summary",
  components: { TheHeader, TheTicketConfirmation, TheFooter },
};
</script>
<style></style>
