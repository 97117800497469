<template>
  <div class="container--fluid pa-0">
    <the-header />
    <the-ticket-confirmation :info="orderSummary" :isFailed="isFailed" />
    <the-footer />
  </div>
</template>

<script>
import TheFooter from "../components/TheFooter.vue";
import TheHeader from "../components/TheHeader.vue";
import TheTicketConfirmation from "../components/TheTicketConfirmation.vue";

export default {
  name: "Payment",
  components: { TheHeader, TheFooter, TheTicketConfirmation },
  data: () => ({
    isFailed: false,
  }),
  watch: {
    "$route.params": {
      handler: function (val) {
        this.verifyPayment(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    orderSummary() {
      return this.$store.state.orders.orderSummary;
    },
  },
  methods: {
    verifyPayment(val) {
      if (val.id !== "cancel") {
        this.$store.dispatch("orders/VERIFY_PAYMENT", {
          session_id: val.id,
        });
      } else {
        this.isFailed = true;
      }
    },
  },
};
</script>

<style></style>
