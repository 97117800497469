<template>
  <div class="container confirmation">
    <v-skeleton-loader
      v-if="!info"
      type="list-item-avatar-three-line, image, article"
    ></v-skeleton-loader>
    <div v-if="info" class="align-center justify-center confirm-msg">
      <img
        src="@/assets/images/confirm.svg"
        alt="confirm"
        class="confirm-img"
      />
      <h3 class="confirm-header">Your tickets are confirmed!</h3>
      <p class="confirm-info ma-0">
        That’s it! Your order has been confirmed and you’re going to
      </p>
      <p class="confirm-info ma-0">
        <span v-if="info.stadium_name">
          {{ info.stadium_name }}
        </span>
        on
        <span v-if="info.fixture_date">
          {{ info.fixture_date | moment("DD/MM/YYYY") }}.
        </span>
      </p>
      <p href="" class="order-num align-center ma-0">
        Order:
        <span v-if="info.id"> {{ info.id }} </span>
      </p>
    </div>
    <div v-if="info" class="align-center justify-center create-acc">
      <v-row>
        <v-col cols="12" sm="6">
          <h6 class="acc-header">Would you like to create an account?</h6>
          <v-btn
            class="create-btn d-flex align-center justify-center"
            elevation="0"
            >Create Account
            <img src="@/assets/images/right-arrow.svg" alt="" class="pl-2"
          /></v-btn>
        </v-col>
        <v-col cols="12" sm="6">
          <ul>
            <li class="create-points">
              <img
                src="@/assets/images/right-green.svg"
                alt=""
                class="pr-5"
              />View & track recent orders
            </li>
            <li class="create-points">
              <img
                src="@/assets/images/right-green.svg"
                alt=""
                class="pr-5"
              />Faster checkout
            </li>
            <li class="create-points">
              <img
                src="@/assets/images/right-green.svg"
                alt=""
                class="pr-5"
              />Updates on latest news & offers
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>
    <div v-if="info" class="align-center justify-center create-acc">
      <h6 class="acc-header">
        <img src="@/assets/images/right-green.svg" alt="" class="pr-5" />Check
        your email and verify your account.
      </h6>
      <p class="create-points ma-0">
        Please click through the link sent to
        <span v-if="info.user"> {{ info.user.email }} </span> to confirm your
        account.
      </p>
      <p class="create-points ma-0">
        Once confirmed you will be able to login and check the status of your
        order(s).
      </p>
    </div>
    <div v-if="info" class="order-details">
      <h6 class="order-header align-center ma-0">Ticket Order Details:</h6>
      <p class="ma-0 order-date">
        {{ info.fixture_date | moment("dddd MMMM Do YYYY") }} | Kickoff:
        <span style="font-weight: bold; color: #0d0033">
          {{ info.start_time }}
        </span>
      </p>
      <h3 class="order-match-name">
        <span> {{ info.team1_name }} </span>
        <span> vs </span>
        <span> {{ info.team2_name }} </span>
      </h3>
      <div class="d-flex align-center justify-center justify-space-between">
        <p class="ma-0 order-match-info">
          <span v-if="info.stadium_category">
            {{ info.stadium_category }}
          </span>
          <span v-if="info.stadium_category_description">
            / {{ info.stadium_category_description }}
          </span>
        </p>
        <p class="ma-0 order-match-info">x{{ info.quantity }} tickets</p>
      </div>
      <div>
        <v-divider class="division"></v-divider>
      </div>
      <div
        v-if="info"
        class="d-flex align-center justify-center justify-space-between"
      >
        <p
          class="ma-0 order-match-info-name d-flex align-center justify-center"
        >
          <span v-if="info.stadium_name">
            {{ info.stadium_name | capitalize }},
          </span>
          <span v-if="info.stadium_city">
            {{ info.stadium_city | capitalize }},
          </span>
          <span v-if="info.stadium_country">
            {{ info.stadium_country | capitalize }}
          </span>
          <img src="@/assets/images/uk-flag.svg" alt="country" class="ml-2" />
        </p>
        <p
          class="ma-0 order-match-info-name d-flex align-center justify-center"
        >
          <!-- <img src="@/assets/images/lion.svg" alt="" class="mr-2" /> -->
          <img
            :src="info.tournament_image"
            :alt="info.tournament_name"
            class="mr-2"
            width="30"
            height="30"
          />
          {{ info.tournament_name | capitalize }}
        </p>
      </div>
      <div>
        <v-divider class="division"></v-divider>
      </div>
      <div v-if="info.user" class="guest-ticket-info">
        <h6 class="order-header align-center ma-0 pt-8">Your Details:</h6>
        <p class="ma-0 order-guest-info">
          {{ info.user.first_name | capitalize }}
          {{ info.user.last_name | capitalize }}
        </p>
        <p class="ma-0 order-guest-info">
          {{ info.user.email }}
        </p>
        <p class="ma-0 order-guest-info">
          {{ info.user.phone }}
        </p>
      </div>
      <div>
        <v-divider class="division"></v-divider>
      </div>
      <div class="guest-ticket-info">
        <h6 class="order-header align-center ma-0 pt-8">Ticket delivery:</h6>
        <p class="ma-0 order-guest-info">
          Your ticket(s) will be delivered to the following address:
        </p>
        <h6 v-if="info" class="ticket-delivery align-center ma-0">
          <span v-if="info.shipping_first_name">
            {{ info.shipping_first_name | capitalize }}
          </span>
          <span v-if="info.shipping_first_name">
            {{ info.shipping_last_name | capitalize }} <br />
          </span>
          <span v-if="info.shipping_phone">
            {{ info.shipping_phone | capitalize }} <br />
          </span>
          <span v-if="info.shipping_mobile">
            {{ info.shipping_mobile | capitalize }} <br />
          </span>
          <!-- other details -->
          <span v-if="info.shipping_company_name">
            {{ info.shipping_company_name | capitalize }} <br />
          </span>
          <span v-if="info.shipping_company_start_time">
            {{ info.shipping_company_start_time | capitalize }} hrs<br />
          </span>
          <span v-if="info.shipping_company_end_time">
            {{ info.shipping_company_end_time | capitalize }} hrs <br />
          </span>
          <span v-if="info.shipping_boarding_name">
            {{ info.shipping_boarding_name | capitalize }} <br />
          </span>
          <span v-if="info.shipping_reservation_name">
            {{ info.shipping_reservation_name | capitalize }} <br />
          </span>
          <span v-if="info.shipping_room_apartment_name">
            {{ info.shipping_room_apartment_name | capitalize }} <br />
          </span>
          <span v-if="info.shipping_checkin_time">
            {{ info.shipping_checkin_time | moment("DD/MM/YYYY") }} -
          </span>
          <span v-if="info.shipping_checkout_time">
            {{ info.shipping_checkout_time | moment("DD/MM/YYYY") }}
            <br />
          </span>
          <!-- basic details -->
          <span v-if="info.shipping_address_line_one">
            {{ info.shipping_address_line_one | capitalize }} <br />
          </span>
          <span v-if="info.shipping_address_line_two">
            {{ info.shipping_address_line_two | capitalize }} <br />
          </span>
          <span v-if="info.shipping_city_name">
            {{ info.shipping_city_name | capitalize }},
          </span>
          <span v-if="info.shipping_county">
            {{ info.shipping_county | capitalize }} <br />
          </span>
          <span v-if="info.shipping_country_name">
            {{ info.shipping_country_name | capitalize }} -
          </span>
          <span v-if="info.shipping_postcode">
            {{ info.shipping_postcode | uppercase }} <br />
          </span>
          <span v-if="info.shipping_map_url">
            <a :href="info.shipping_map_url"> View on map </a>
          </span>
        </h6>
      </div>
      <div>
        <v-divider class="division"></v-divider>
      </div>
      <div>
        <p class="ma-0 order-disclaimer-info">
          If you have any questions or queries about your order, then please
          <span style="font-weight: bold"> contact us </span> or call 0871 284
          5277 with your order number and we will be happy to help. For more
          information, please visit our
          <span style="font-weight: bold"> FAQs </span> and read our
          <span style="font-weight: bold"> Disclaimer.</span>
        </p>
      </div>
    </div>
    <div v-if="isFailed" class="align-center justify-center confirm-msg pb-8">
      <img
        src="@/assets/images/order-not-confirm.svg"
        alt=""
        class="confirm-img"
      />
      <h3 class="confirm-header">Sorry, your order didn’t go through.</h3>
      <p class="confirm-info ma-0 align-center justify-center">
        It looks like there was an issue confirming your order. Please go
      </p>
      <p class="confirm-info ma-0 align-center justify-center">
        back and try again. If your problem persists, please
        <a href="" style="color: rgba(13, 0, 51, 0.8)">get in touch</a>
      </p>
      <p class="confirm-info ma-0 align-center justify-center">
        or call 0871 284 5277.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "TicketConfirmation",
  props: {
    info: {
      Type: Object,
      default() {
        return null;
      },
    },
    isFailed: {
      Type: Boolean,
      default() {
        return false;
      },
    },
  },
  destroyed() {
    this.$store.dispatch("orders/RESET", null);
  },
};
</script>
<style></style>
